<template>
  <div class="dashboard bg-black">
    <div
      class="splashScreen w-screen fixed top-1/4 left-0 flex justify-center items-center z-50"
    >
      <img
        :src="require('@/assets/images/animated-logo.gif')"
        class="w-full h-48"
      />
    </div>
    <div class="mainContent gap-4 justify-items-center text-center hidden">
      <div class="flex flex-col justify-center items-center pt-8">
        <img :src="logo" class="w-1/3" />
        <div
          class="text-nColorYellow text-5xl text-center mb-2"
          v-if="businessName && businessLocation"
        >
          <span class="font-ZuumeExtraBold business-name" ref="businessNameRef">
            {{ businessName }}
          </span>
          <div class="text-nColorYellow text-base font-ZuumeSemiBold">
            {{ businessLocation }}
          </div>
        </div>
        <div v-if="mobileSection">
          <input
            type="text"
            name="name"
            ref="name"
            id="name"
            v-model="name"
            placeholder="Name"
            class="textInput"
            autofocus
            required
          />
          <input
            type="tel"
            name="mobile"
            ref="mobile"
            id="mobile"
            v-model="mobile"
            @keypress="isNumber($event)"
            maxlength="10"
            @input="checkLength"
            placeholder="Mobile"
            class="textInput mt-2"
            required
          />
        </div>
        <div v-if="otpSection">
          <div class="font-InterRegular text-xs mx-8 mt-4 text-white">
            Enter the 4-digit OTP that we have sent to
            <span class="font-InterMedium"
              ><strong>{{ mobile }}</strong></span
            >
            <div
              class="flex gap-2 font-ManropeMedium mt-4 text-xs text-nColorOrange justify-center"
            >
              <span class="text-gray-400">Wrong number?</span>
              <button @click="enableMobile" class="underline font-bold">
                Change number
              </button>
            </div>
          </div>
          <div class="flex justify-center gap-4 mt-4">
            <input
              v-for="i in 4"
              :key="i"
              @keydown.space.prevent=""
              maxlength="1"
              class="otpText"
              type="number"
              @input="handleInput(i, $event)"
              @keydown.delete="movePrev(i)"
              :ref="'otp' + i"
            />
          </div>
          <div class="flex gap-4 justify-center">
            <div class="font-ManropeMedium mt-4 text-sm">
              <button :disabled="resendDisabled" @click="resendOTP">
                <span
                  :class="{
                    'text-gray-400': resendDisabled,
                    'text-nColorOrange underline font-bold': !resendDisabled,
                  }"
                >
                  Resend OTP
                </span>
                <span v-if="resendDisabled" class="text-gray-400"
                  >({{ countdown }})</span
                >
              </button>
            </div>
          </div>
        </div>
        <div class="w-full text-center">
          <div class="text-sm inline-block mt-2 h-4">
            <div v-if="errorMsg" class="error text-red-600 rounded">
              <p class="text-sm">{{ errorMsg }}</p>
            </div>
            <div v-if="successMsg" class="success text-green-700">
              <p class="text-sm">
                {{ successMsg }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-section absolute text-center">
        <div class="flex justify-center pr-6 relative">
          <div class="w-2/5 absolute bottom-14">
            <img
              :src="require('@/assets/images/illustration-home.webp')"
              v-if="mobileSection"
            />
          </div>
          <div class="button absolute bottom-1 w-full flex justify-center pl-6">
            <button
              @click.prevent="validateMobile"
              :class="[
                'shadow-orange-right bg-nColorYellow rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                { disabled: isLoading },
              ]"
              v-if="mobileSection"
              :disabled="isLoading"
            >
              <span v-if="isLoading">
                <svg
                  width="120"
                  height="30"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> GET OTP </span>
            </button>
            <button
              @click.prevent="validateOTP()"
              :class="[
                'shadow-orange-right bg-nColorYellow rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                { disabled: isSubmitLoading },
              ]"
              v-if="otpSection"
              :disabled="isSubmitLoading"
            >
              <span v-if="isSubmitLoading">
                <svg
                  width="120"
                  height="30"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> SUBMIT </span>
            </button>
          </div>
        </div>
        <div class="text-center mt-1 px-8 text-sm text-white">
          <p class="font-ManropeMedium text-xs">
            By signing up, you agree to our
            <a :href="termsURL" class="hyperlink">Terms of Use</a>
            and
            <a :href="privacyURL" class="hyperlink">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
    <Copyright :absoluteBottom="calculatedBottom" :textColor="'#555'" />
    <div
      id="allow-promotions-blur"
      class="absolute top-0 w-screen h-screen bg-nColorDarkGray blurry-div z-10 hidden"
    ></div>
    <div
      id="allow-promotions-content"
      class="allow-promotions absolute bottom-0 w-screen bg-nColorDarkGray rounded-t-2xl z-20 hidden"
    >
      <div class="promotion-lottie absolute -top-32 -left-24 transform">
        <lottie-animation
          id="promotionsLottie"
          path="lotties/promotions.json"
          :loop="false"
          :autoPlay="false"
          :speed="1"
          :width="320"
          :height="220"
          @AnimControl="setAnimController"
        />
      </div>
      <div class="absolute z-30">
        <div
          class="mx-3 mt-12 w-11/12 bg-nColorDarkGray border border-nColorYellow shadow-yellow-right-top rounded-xl p-4"
        >
          <p class="font-ZuumeExtraBold text-4xl text-nColorYellow">
            Stay in the loop!
          </p>
          <p class="text-white font-InterMedium text-sm">
            About the latest
            <span class="text-nColorYellow">promotions, events,</span> and
            <span class="text-nColorYellow">exciting news</span> from Playda!
            and our trusted partners on Whatsapp!
          </p>
        </div>
        <div class="buttons flex gap-2 p-3">
          <div
            @click.prevent="dontAllowPromotions()"
            class="shadow-yellow-right border border-nColorDarkGray bg-nColorOrange rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold focus:outline-none"
          >
            No, thanks!
          </div>
          <div
            @click.prevent="allowPromotions()"
            class="shadow-orange-right border border-nColorDarkGray bg-nColorYellow rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold focus:outline-none"
          >
            Sure.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Copyright from "@/components/elements/Copyright.vue";
import slugs from "@/common/slugs";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "Register",
  components: { Copyright, LottieAnimation },
  data() {
    return {
      businessName: this.$store.getters.businessName,
      businessLocation: this.$store.getters.businessLocation,
      businessCategory: this.$store.getters.businessCategory,
      logo: this.$store.getters.businessLogo,
      name: null,
      mobile: null,
      errorMsg: null,
      successMsg: null,
      mobileSection: true,
      otpSection: false,
      resendDisabled: false,
      countdown: 60,
      isLoading: false,
      isSubmitLoading: false,
      otp: "",
      slugs,
      animationController: null,
      termsURL: process.env.VUE_APP_TERMS_URL,
      privacyURL: process.env.VUE_APP_PRIVACY_URL,
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      document.querySelector(".splashScreen").classList.add("hidden");
      document.querySelector(".mainContent").classList.remove("hidden");
      document.querySelector(".mainContent").classList.add("grid");
      this.resizeText();
    }, 5000);
  },
  computed: {
    calculatedBottom() {
      const screenHeight = window.innerHeight;
      if (screenHeight < 650) {
        return "0.7";
      } else {
        return "1";
      }
    },
  },
  methods: {
    checkLength() {
      let mobile = document.querySelector("#mobile");
      if (mobile.value.length > mobile.maxLength)
        mobile.value = mobile.value.slice(0, mobile.maxLength);
    },
    isNumber: function (event) {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    handleInput(index, event) {
      const inputValue = event.target.value;

      if (inputValue.length > 1) {
        event.target.value = inputValue.charAt(0);
      }

      if (inputValue.length === 1 && index < 4) {
        const nextInputRef = `otp${index + 1}`;
        const nextInput = this.$refs[nextInputRef][0];
        nextInput.focus();
      }

      if (inputValue.length === 0 && index > 1) {
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }

      this.updateOTPValue();
    },
    movePrev(index) {
      if (index > 1) {
        const currentInputRef = `otp${index}`;
        const currentInput = this.$refs[currentInputRef][0];
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];

        currentInput.value = ""; // Clear current input value
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }
    },
    updateOTPValue() {
      const otpInputValues = Array.from(
        document.querySelectorAll(".otpText")
      ).map((input) => input.value);

      this.otp = otpInputValues.join("");
    },
    enableMobile() {
      const otpElements = document.querySelectorAll(".otpText");

      otpElements.forEach((element) => {
        element.value = ""; // Clear the value
      });
      this.mobileSection = true;
      this.otpSection = false;
    },
    resendOTP() {
      ApiService.post(apiResource.resendOTP, {
        business_slug: this.$route.params.business,
        mobile: this.mobile,
      })
        .then(() => {
          this.$refs["otp1"][0].focus();
          this.startResendCounter();
        })
        .catch(() => {});
    },
    startResendCounter() {
      this.resendDisabled = true;
      this.countdown = 60;

      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resendDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    validateMobile() {
      this.isLoading = true;
      this.errorMsg = "";
      if (this.name && this.mobile) {
        ApiService.post(apiResource.validateMobile, {
          business_slug: this.$route.params.business,
          name: this.name,
          mobile: this.mobile,
        })
          .then(() => {
            this.isLoading = false;
            this.mobileSection = false;
            this.otpSection = true;
            this.startResendCounter();
          })
          .catch((error) => {
            this.isLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isLoading = false;
        this.errorMsg = "Please enter name and mobile";
      }
    },
    validateOTP() {
      this.isSubmitLoading = true;
      this.errorMsg = "";
      if (this.name && this.mobile && this.otp) {
        this.$store
          .dispatch("validateOTP", {
            mobile: this.mobile,
            business_slug: this.$route.params.business,
            otp: this.otp,
          })
          .then((data) => {
            this.isSubmitLoading = false;
            if (data.show_promotions_message) {
              this.openAllowPromotions();
            } else {
              this.goToLobby();
            }
          })
          .catch(({ error }) => {
            this.isSubmitLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isSubmitLoading = false;
        this.errorMsg = "Please enter the OTP";
      }
    },
    allowPromotions() {
      this.allow_promotions = true;
      this.updateAllowPromotions();
    },
    dontAllowPromotions() {
      this.allow_promotions = false;
      this.updateAllowPromotions();
    },
    updateAllowPromotions() {
      this.$store.dispatch("checkAuth");
      ApiService.post(apiResource.updateAllowNotifications, {
        allow_promotions: this.allow_promotions,
      })
        .then(() => {
          this.goToLobby();
        })
        .catch(() => {});
    },
    goToLobby() {
      this.$router.replace({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    resizeText() {
      const businessNameElement = this.$refs.businessNameRef;
      const containerWidth = businessNameElement.clientWidth;
      const originalText = this.businessName;
      let fontSize = 30; // Default font size
      const maxFontSize = 60; // Maximum font size

      // Create a temporary element to measure the width of the text
      const tempElement = document.createElement("span");
      tempElement.style.visibility = "hidden";
      tempElement.style.fontSize = fontSize + "px";
      tempElement.textContent = originalText;
      document.body.appendChild(tempElement);

      // Reduce font size until it fits within the container width
      while (tempElement.clientWidth > containerWidth && fontSize > 0) {
        fontSize--;
        tempElement.style.fontSize = fontSize + "px";
      }

      // Remove the temporary element
      document.body.removeChild(tempElement);

      // Set the final font size for the business name element
      businessNameElement.style.fontSize =
        Math.min(fontSize, maxFontSize) + "px";
    },
    openAllowPromotions() {
      document
        .querySelector("#allow-promotions-blur")
        .classList.remove("hidden");
      document
        .querySelector("#allow-promotions-content")
        .classList.remove("hidden");
      document.querySelector("#allow-promotions-content").classList.add("show");
      this.playAnimation();
    },
    closeAllowPromotions() {
      document.querySelector("#allow-promotions-blur").classList.add("hidden");
      document
        .querySelector("#allow-promotions-content")
        .classList.add("hidden");
      document
        .querySelector("#allow-promotions-content")
        .classList.remove("show");
    },
    setAnimController(controller) {
      // Store the animation controller function
      this.animationController = controller;
    },
    playAnimation() {
      // Check if the animation controller function is available
      if (this.animationController) {
        // Use the animation controller to play the animation
        this.animationController.play();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
.hyperlink {
  @apply text-nColorOrange;
}
.textInput {
  @apply text-white border border-nColorYellow rounded-lg block appearance-none focus:bg-nColorOrange focus:outline-none bg-transparent w-80 py-2.5 px-2 placeholder-nColorYellow text-xs font-InterMedium;
}
.otpText {
  @apply bg-nColorYellow rounded font-ZuumeSemiBold text-black border border-black text-3xl focus:outline-none focus:bg-nColorOrange text-center w-10 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled {
  @apply bg-nColorBGDarkGray;
  pointer-events: none;
}
svg {
  width: 35px;
  height: 35px;
  display: inline-block;
}

.sign {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffe6ff;
  font-size: 1.9rem;
  text-shadow: 0 0 0.19rem #ffe6ff, 0 0 0.475rem #ff65bd,
    -0.038rem 0.019rem 0.095rem #ff65bd, 0.038rem 0.019rem 0.095rem #ff65bd,
    0 -0.095rem 0.38rem #ff2483, 0 0.095rem 0.57rem #ff2483;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.19rem #ffe6ff, 0 0 0.475rem #ff65bd,
      -0.038rem 0.019rem 0.095rem #ff65bd, 0.038rem 0.019rem 0.095rem #ff65bd,
      0 -0.095rem 0.38rem #ff2483, 0 0.095rem 0.57rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 1s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 6s infinite;
}

/* New styles for individual character animations */
#char1 {
  animation-delay: 0.2s;
}

#char2 {
  animation-delay: 0.4s;
}

#char3 {
  animation-delay: 0.6s;
}

#char4 {
  animation-delay: 0.8s;
}

#char5 {
  animation-delay: 1s;
}

#char6 {
  animation-delay: 1.2s;
}

#char7 {
  animation-delay: 1.4s;
}

#char8 {
  animation-delay: 1.6s;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.19rem #ffe6ff, 0 0 0.475rem #ff65bd,
      -0.038rem 0.019rem 0.095rem #ff65bd, 0.038rem 0.019rem 0.095rem #ff65bd,
      0 -0.095rem 0.38rem #ff2483, 0 0.095rem 0.57rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

.bottom-section {
  @apply bottom-10;
}
@media (min-height: 650px) {
  .bottom-section {
    @apply bottom-16;
  }
}
.blurry-div {
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.5);
}
.allow-promotions {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  transition: height 0.6s ease;
}
.allow-promotions.show {
  height: 16.5rem;
}
.promotion-lottie {
  rotate: 30deg;
}
</style>
