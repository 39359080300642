<template>
  <div class="dashboard bg-white">
    <div
      class="splashScreen w-screen h-screen fixed top-0 left-0 flex justify-center items-center z-50 bg-white"
    >
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/miror/intro-animation.gif"
        class="w-full h-48"
      />
    </div>
    <div class="mainContent gap-4 justify-items-center text-center">
      <div class="flex flex-col justify-center items-center gap-2 pt-6">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/miror/miror_logo.webp"
          class="w-3/5"
        />
        <div
          class="text-black text-lg text-center mb-2 font-MontserratAlternatesSemiBold"
        >
          Play your way to a Healthier you!
        </div>
        <div>
          <input
            type="text"
            name="name"
            ref="name"
            id="name"
            v-model="name"
            placeholder="Name"
            class="textInput"
            autofocus
            required
          />
          <input
            type="tel"
            name="mobile"
            ref="mobile"
            id="mobile"
            v-model="mobile"
            @keypress="isNumber($event)"
            maxlength="10"
            @input="checkLength"
            placeholder="Mobile"
            class="textInput mt-2"
            required
          />
          <div v-if="errorMsg" class="error text-red-600 rounded">
            <p class="text-sm">{{ errorMsg }}</p>
          </div>
        </div>
      </div>
      <div class="bottom-section absolute text-center w-full">
        <div class="grid justify-center pr-6 relative w-full">
          <div class="w-full flex justify-center">
            <div class="w-screen flex justify-center">
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/miror/sign_up_illustration.webp"
                width="183"
                height="250"
              />
            </div>
          </div>
          <div class="w-full flex justify-center pl-6">
            <button
              @click.prevent="validateMobile"
              :class="[
                'bg-mirorColors-pink rounded-3xl w-5/6 text-white text-center h-10 text-xl font-MontserratAlternatesSemiBold tracking-wider focus:outline-none',
                { disabled: isLoading },
              ]"
              :disabled="isLoading"
            >
              <span v-if="isLoading">
                <svg
                  width="120"
                  height="30"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> Submit </span>
            </button>
          </div>
        </div>
        <div class="grid gap-2 mt-2">
          <div class="text-nColorLightGray text-sm">Powered by</div>
          <div class="flex justify-center">
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/miror/playda_footer.webp"
              alt="Playda Logo"
              width="68"
              height="30"
            />
          </div>
        </div>
      </div>
      <div
        id="popup-blur"
        class="absolute top-0 w-screen h-screen bg-mirorColors-pink blurry-div z-10 hidden"
      ></div>
      <div
        id="popup-content"
        class="popup absolute bottom-0 w-screen bg-mirorColors-pink rounded-t-2xl z-20 hidden"
      >
        <div class="absolute z-30">
          <div class="text-white py-6">
            Playda is available only for the Miror community. Please register on
            Miror to use Playda.
          </div>
          <div class="buttons flex gap-2 p-3">
            <div
              @click.prevent="closePopup()"
              class="bg-white rounded-3xl w-5/6 text-mirorColors-pink text-center py-1 text-xl font-MontserratAlternatesMedium focus:outline-none"
            >
              Cancel
            </div>
            <a
              :href="redirectURL"
              class="bg-white rounded-3xl w-5/6 text-mirorColors-pink text-center py-1 text-xl font-MontserratAlternatesMedium focus:outline-none"
            >
              Go To Miror
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import slugs from "@/common/slugs";
import { getPlatform } from "@/common/platform";
// import axios from "axios";

export default {
  name: "Register",
  components: {},
  data() {
    return {
      businessName: this.$store.getters.businessName,
      businessLocation: this.$store.getters.businessLocation,
      businessCategory: this.$store.getters.businessCategory,
      name: null,
      mobile: null,
      errorMsg: null,
      successMsg: null,
      resendDisabled: false,
      countdown: 60,
      isLoading: false,
      isSubmitLoading: false,
      otp: "",
      slugs,
      animationController: null,
      termsURL: process.env.VUE_APP_TERMS_URL,
      privacyURL: process.env.VUE_APP_PRIVACY_URL,
      platform: "unknown",
      iOSURL:
        "https://apps.apple.com/in/app/miror-menopause-support/id6502974559?platform=iphone",
      androidURL: "https://play.google.com/store/apps/details?id=com.miror.app",
      redirectURL: null,
    };
  },
  created() {},
  mounted() {
    this.platform = getPlatform();
    if (this.platform === "iOS") {
      this.redirectURL = this.iOSURL;
    } else {
      this.redirectURL = this.androidURL;
    }
    setTimeout(() => {
      document.querySelector(".splashScreen").classList.add("hidden");
      document.querySelector(".mainContent").classList.remove("hidden");
      document.querySelector(".mainContent").classList.add("grid");
    }, 2800);
  },
  computed: {
    calculatedBottom() {
      const screenHeight = window.innerHeight;
      if (screenHeight < 650) {
        return "0.7";
      } else {
        return "1";
      }
    },
  },
  methods: {
    checkLength() {
      let mobile = document.querySelector("#mobile");
      if (mobile.value.length > mobile.maxLength)
        mobile.value = mobile.value.slice(0, mobile.maxLength);
    },
    isNumber: function (event) {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    handleInput(index, event) {
      const inputValue = event.target.value;

      if (inputValue.length > 1) {
        event.target.value = inputValue.charAt(0);
      }

      if (inputValue.length === 1 && index < 4) {
        const nextInputRef = `otp${index + 1}`;
        const nextInput = this.$refs[nextInputRef][0];
        nextInput.focus();
      }

      if (inputValue.length === 0 && index > 1) {
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }

      this.updateOTPValue();
    },
    movePrev(index) {
      if (index > 1) {
        const currentInputRef = `otp${index}`;
        const currentInput = this.$refs[currentInputRef][0];
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];

        currentInput.value = ""; // Clear current input value
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }
    },
    updateOTPValue() {
      const otpInputValues = Array.from(
        document.querySelectorAll(".otpText")
      ).map((input) => input.value);

      this.otp = otpInputValues.join("");
    },
    enableMobile() {
      const otpElements = document.querySelectorAll(".otpText");

      otpElements.forEach((element) => {
        element.value = ""; // Clear the value
      });
      this.mobileSection = true;
      this.otpSection = false;
    },
    resendOTP() {
      ApiService.post(apiResource.resendOTP, {
        business_slug: this.$route.params.business,
        mobile: this.mobile,
      })
        .then(() => {
          this.$refs["otp1"][0].focus();
          this.startResendCounter();
        })
        .catch(() => {});
    },
    startResendCounter() {
      this.resendDisabled = true;
      this.countdown = 60;

      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resendDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    async validateMobile() {
      this.isLoading = true;
      this.errorMsg = "";
      if (this.name && this.mobile) {
        ApiService.mirorPost(process.env.VUE_APP_MIROR_API_URL, {
          // ApiService.simplePost(apiResource.miror.checkPhone, {
          phone: this.mobile,
        })
          .then((data) => {
            this.isLoading = false;
            if (data.data.exists === true) {
              this.goToLobby(data.data.userDetails.email);
              console.log("logged in");
            } else {
              this.openPopup();
              console.log("no user");
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.isLoading = false;
        this.errorMsg = "Please enter name and mobile";
      }
    },
    goToLobby(email) {
      this.$store
        .dispatch("loginMirorUser", {
          mobile: this.mobile,
          name: this.name,
          email: email,
        })
        .then(() => {
          this.goToLobbyPage();
        })
        .catch(() => {});
    },
    goToLobbyPage() {
      this.$router.replace({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    setAnimController(controller) {
      // Store the animation controller function
      this.animationController = controller;
    },
    playAnimation() {
      // Check if the animation controller function is available
      if (this.animationController) {
        // Use the animation controller to play the animation
        this.animationController.play();
      }
    },
    openPopup() {
      document.querySelector("#popup-blur").classList.remove("hidden");
      document.querySelector("#popup-content").classList.remove("hidden");
      document.querySelector("#popup-content").classList.add("show");
      this.playAnimation();
    },
    closePopup() {
      document.querySelector("#popup-blur").classList.add("hidden");
      document.querySelector("#popup-content").classList.add("hidden");
      document.querySelector("#popup-content").classList.remove("show");
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
.hyperlink {
  @apply text-nColorOrange;
}
.textInput {
  @apply text-mirorColors-pink border border-mirorColors-pink rounded-lg block appearance-none focus:bg-mirorColors-pink focus:text-white focus:outline-none bg-transparent w-80 p-2 placeholder-mirorColors-pink text-xs font-MontserratMedium;
}
.otpText {
  @apply bg-mirorColors-pink rounded font-MontserratSemiBold text-black border border-black text-3xl focus:outline-none focus:bg-mirorColors-pink focus:text-white text-center w-10 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled {
  @apply bg-nColorBGDarkGray;
  pointer-events: none;
}
svg {
  width: 35px;
  height: 35px;
  display: inline-block;
}

.sign {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffe6ff;
  font-size: 1.9rem;
  text-shadow: 0 0 0.19rem #ffe6ff, 0 0 0.475rem #ff65bd,
    -0.038rem 0.019rem 0.095rem #ff65bd, 0.038rem 0.019rem 0.095rem #ff65bd,
    0 -0.095rem 0.38rem #ff2483, 0 0.095rem 0.57rem #ff2483;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.19rem #ffe6ff, 0 0 0.475rem #ff65bd,
      -0.038rem 0.019rem 0.095rem #ff65bd, 0.038rem 0.019rem 0.095rem #ff65bd,
      0 -0.095rem 0.38rem #ff2483, 0 0.095rem 0.57rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 1s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 6s infinite;
}

/* New styles for individual character animations */
#char1 {
  animation-delay: 0.2s;
}

#char2 {
  animation-delay: 0.4s;
}

#char3 {
  animation-delay: 0.6s;
}

#char4 {
  animation-delay: 0.8s;
}

#char5 {
  animation-delay: 1s;
}

#char6 {
  animation-delay: 1.2s;
}

#char7 {
  animation-delay: 1.4s;
}

#char8 {
  animation-delay: 1.6s;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.19rem #ffe6ff, 0 0 0.475rem #ff65bd,
      -0.038rem 0.019rem 0.095rem #ff65bd, 0.038rem 0.019rem 0.095rem #ff65bd,
      0 -0.095rem 0.38rem #ff2483, 0 0.095rem 0.57rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

.bottom-section {
  @apply bottom-10;
}
.blurry-div {
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.5);
}
.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  transition: height 0.6s ease;
}
.popup.show {
  height: 11.5rem;
}
.promotion-lottie {
  rotate: 30deg;
}
</style>
